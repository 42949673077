import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import { Button, notification, Modal, Spin } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import createExcelAndZipFile from './CreateExcelAndZipFile';

const ClientExcel = ({ salaries, month, year, user, selectedCompany, language }) => {
  let submittedIndicator = false;

  const [isVisible, setIsVisible] = useState(false);

  let foundCompany =
    salaries &&
    salaries.length !== 0 &&
    salaries.filter(
      (salary) =>
        (!user.data.role.includes('admin') &&
          !user.data.role.includes('SuperAdmin') &&
          (salary.employee.company?.client?._id === user.data.id ||
            salary.employee.company?.userOfficer?.includes(user.data.id))) ||
        ((user.data.role.includes('SuperAdmin') || user.data.role.includes('admin')) &&
          salary.employee.company?._id === selectedCompany),
    );

  if (foundCompany.length === 0) {
    foundCompany = salaries && salaries.length !== 0 && (salaries[0]?.employee && salaries[0].employee.company.mainCompany) || (salaries?.data?.items[0]?.employee?.company?.mainCompany?._id) || (salaries[0]?.employee?.company?.mainCompany?._id);
  } else if (foundCompany) {
    foundCompany = foundCompany[0].employee.company;
  }

  const company = foundCompany;
  const months = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  const monthDays = new Date(year, months[month], 0).getDate();
  let filesToDownload = [];
  for (const data of salaries) {
    if (data.attachments) {
      for (const attachment of data.attachments) {
        filesToDownload.push({ ...attachment, employee: data.employee.firstName + ' ' + data.employee.lastName });
      }
    }
  }

  salaries.map((item) => {
    if (item) submittedIndicator = true;
  });

  return (
    <div>
      <Button
        id='clientDownload'
        size='small'
        icon={<DownloadOutlined />}
        disabled={!submittedIndicator}
        onClick={() => {
          if (salaries.length !== 0) {
            setIsVisible(true);
            createExcelAndZipFile(
              salaries,
              month,
              year,
              user,
              selectedCompany,
              language,
              monthDays,
              company,
              months,
              FileSaver,
              filesToDownload,
              setIsVisible,
            );
          } else {
            notification.info({
              message: 'No data to export.',
              placement: 'bottomRight',
            });
          }
        }}
      >
        Download
      </Button>
      <Modal visible={isVisible} maskClosable={false} closable={false} footer={null}>
        <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
          <p>Creating excel and/or zip file in progress.</p>
          <p>Please wait. This may take up to a minute depending on number of employees.</p>
          <p>
            <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ClientExcel;
