// TODO update this object every year
// http://demo.paragraf.rs/demo/documents/editorial/statistika/02_stat.htm
export const minimalWageInSerbia = {
    January: 56672,
    February: 49280,
    March: 51744,
    April: 54208,
    May: 54208,
    June: 51744,
    July: 56672,
    August: 51744,
    September: 54208,
    October: 56672,
    November: 49280,
    December: 56672
}