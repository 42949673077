import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Tag,
  Tabs,
  Tooltip,
  Upload,
  Modal,
} from 'antd';
import { useParams } from 'react-router-dom';
import { DeleteOutlined, QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import PaymentSlipsForm from './PaymentSlipsForm';
import StatuaryDocsForm from './StatuaryDocsForm';
import { UserContext } from '../../App.js';
import FunctionaryAndOwnershipForm from './FunctionaryAndOwnershipForm';
import CompanyOwnershipDrawer from '../CompanyOwnershipDrawer';
import UploadCompanyFilesForm from './UploadCompanyFilesForm';
import QuestionnaireForm from './QuestionnaireForm';

const { Option } = Select;
const { TabPane } = Tabs;

const tailLayout = {
  wrapperCol: { span: 24 },
};

const COMPANY_FORM_NOTIFICATION_KEY = 'company-form-key';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const initialValues = {
  code: undefined,
  name: undefined,
  authorizedPersons: undefined,
  zip: '',
  city: '',
  address: undefined,
  PIB: undefined,
  client: null,
  eInvoiceOfficer: null,
  otherIncome: true,
  suspension: true,
  otherExpenses: true,
  mealCurrency: 'rsd',
  holidayCurrency: 'rsd',
  travelExpCurrency: 'rsd',
  workFromHomeCurrency: 'rsd',
  bonusCurrency: 'rsd',
  otherIncomeCurrency: 'rsd',
  suspensionCurrency: 'rsd',
  otherExpensesCurrency: 'rsd',
  type: 'main',
  isHr: false,
  holidayDaysTracker: false,
  isSector: false,
  isHrOfficerSelfApprove: true,
  typeOfHr: 'serbian',
  eInvoice: false,
  apiKey: undefined,
  bankAccount: undefined,
  phoneNumber: undefined,
  workFromHome: undefined,
  companyAdmin: [],
  companyAdminReceiveHREmails: true,
  documentReturnType: 'word',
  weekendLeaves: false,
  email: undefined,
  qrInEInvoices: false,
  companyLogo: {
    typeOfFile: undefined,
    location: undefined,
    originalName: undefined,
  },
  isForEmployees: false,
};

const CompanyForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  clients,
  SERVER_URL,
  user,
  companies,
  einvoiceOfficers,
  userOfficers,
  admins,
  createPaymentSlip,
  createdPaymentSlips,
  paymentSlipsEmailReceivers,
  setPaymentSlipsEmailReceivers,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const typeOfWorkRef = useRef(null);

  const currentuser = useContext(UserContext);

  const { companyId } = useParams();

  const [userRole, setUserRole] = useState(user.role[0]);
  const [canUserUpdate, setCanUserUpdate] = useState(false);
  const [companyType, setCompanyType] = useState(data && data.type ? data.type : 'main');
  const [isHrChecked, setIsHrChecked] = useState(data && data.isHr);
  const [isSectorsChecked, setIsSectorsChecked] = useState(data && data.isSector);
  const [isHrOfficerSelfApprove, setIsHrOfficerSelfApprove] = useState(data && data.isHrOfficerSelfApprove);
  const [workFromHomeType, setWorkFromHomeType] = useState(data && data.workFromHomeType);
  const [iseInvoiceChecked, setIseInvoiceChecked] = useState(data && data.eInvoice);
  const [companyLogoChecked, setCompanyLogoChecked] = useState(data ? data.isCompanyLogoChecked : false);
  const [isOtherIncomeChecked, setIsOtherIncomeChecked] = useState(data?.otherIncome);
  const [isSuspensionChecked, setIsSuspensionChecked] = useState(data?.suspension);
  const [isOtherExpensesChecked, setIsOtherExpensesChecked] = useState(data?.otherExpenses);
  const [isBonusChecked, setIsBonusChecked] = useState(data?.bonus);
  const [isGrossBonusChecked, setIsGrossBonusChecked] = useState(data?.isGrossBonus);

  const [trackerStartDate, setTrackerStartDate] = useState(undefined);
  const [isSpecialHolidayAllowanceCalculationChecked, setIsSpecialHolidayAllowanceCalculationChecked] = useState(
    data?.isSpecialHolidayAllowanceCalculation || false,
  );
  const [salaryCalculationType, setSalaryCalculationType] = useState(
    data?.salaryCalculationType ? data?.salaryCalculationType : 'average',
  );
  const [isHolidayDaysTracker, setIsHolidayDaysTracker] = useState(data && data.holidayDaysTracker);
  const [validationError2, setValidationError2] = useState('');
  const [accountSections2, setAccountSections2] = useState({
    section1: '',
    section2: '',
    section3: '',
  });
  const [validationErrors2, setValidationErrors2] = useState({
    section1: '',
    section2: '',
    section3: '',
  });
  const [overallErrorMessage2, setOverallErrorMessage2] = useState('');
  const [emails, setEmails] = useState(data && data.emailReceiver[0] !== '' ? data.emailReceiver : []);
  const [companyAdminReceiveHREmails, setCompanyAdminReceiveHREmails] = useState(
    data && data.companyAdminReceiveHREmails ? data.companyAdminReceiveHREmails : true,
  );
  const [isForeignWorkPermitTracker, setIsForeignWorkPermitTracker] = useState(
    data && data.foreignWorkPermitTracker ? data.foreignWorkPermitTracker : false,
  );

  // START: Image upload state

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isError, setIsError] = useState(false);

  // END: Image upload state
  // START: Image Upload Handlers

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleBeforeUpload = (file, fileList) => {
    setIsError(false);

    if (file.size / 1000000 > 5) {
      notification.error({
        message: 'File is larger than 5 MB!',
        placement: 'bottomRight',
      });
      return false;
    }
    return true;
  };

  const handleChange = ({ file, fileList: newFileList }) => {
    if (!file.status) {
      setIsError(true);
    }
    if (file.status === 'done') {
      setIsError(false);
      setFiles([...files, { location: file.response.key, originalName: file.name }]);
      for (const singleFile of newFileList) {
        let nameArray = file.name.split('.');
        switch (nameArray[nameArray.length - 1]) {
          case 'png':
            singleFile.thumbUrl = '/png.png';
            break;
          case 'jpg':
            singleFile.thumbUrl = '/jpg.png';
            break;
          default:
            singleFile.thumbUrl = '/other.png';
            break;
        }
      }
      notification.success({
        message: 'Upload successfull',
        placement: 'bottomRight',
      });
    } else if (file.status === 'error') {
      notification.error({
        message: 'Upload failed',
        placement: 'bottomRight',
      });
    }
    setFileList(newFileList.filter((item) => item.status && item.status !== 'error'));
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const companyData = JSON.stringify({
        pib: data.pib,
        name: data.name,
      });
      const encodedCompanyData = encodeURIComponent(companyData);

      const serverUrl = `${SERVER_URL}/upload-s3?company=${encodedCompanyData}`;

      const response = await Axios.post(serverUrl, formData, {
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          uri: 'static/image/',
          timestamp: Date.now().toString(),
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.round((loaded / total) * 100) });
        },
      });

      onSuccess(response.data);
      setFileList([
        {
          uid: file.uid,
          name: file.name,
          status: 'done',
          fileType: response.data.mimetype,
          url: response.data.signedUrl,
          location: response.data.location,
        },
      ]);
    } catch (error) {
      onError(error);
    }
  };

  // END: Image Upload Handlers

  const isNew = !data;

  const [disabledFieldsList, setDisabledList] = useState([]);
  const [hiddenFieldsList, setHiddenFieldsList] = useState([]);
  const [weekendLeaves, setWeekendLeaves] = useState(data && data.weekendLeaves);
  const [qrInEInvoices, setQrInEInvoices] = useState(data && data.qrInEInvoices);
  const [companyOwnershipDrawer, setCompanyOwnershipDrawer] = useState(false);
  const [isForEmployees, setIsForEmployees] = useState((data && data.isForEmployees) || false);
  const [bankAccountSectionVisible, setBankAccountSectionVisible] = useState(false);
  const [accounts, setAccounts] = useState(
    (data &&
      data.bankAccountsArray?.length &&
      data.bankAccountsArray.map((item) => {
        return {
          id: Date.now(),
          section1: item.substring(0, 3) || '',
          section2: item.substring(3, 16) || '',
          section3: item.substring(16, 18) || '',
          isDefault: false,
        };
      })) ||
      [],
  );

  const defaultTypeOfWorkValue = [
    'Regular work',
    'Holiday leave',
    'Public holiday',
    'Sick leave up to 30 days 65%',
    'Sick leave more than 30 days 65%',
    'Maternity leave',
    'Paid leave',
    'Unemployed',
    'Work on public holiday',
  ];

  const handleIsDisabledHidden = useCallback((userRole) => {
    if (userRole === 'admin' || userRole === 'superAdmin') {
      setDisabledList([]);
      setHiddenFieldsList([]);
      setCanUserUpdate(true);
    }
    if (userRole === 'user' || userRole === 'userOfficer') {
      const disabledList = [
        'isHr',
        'name',
        'clientOfficer',
        'eInvoiceOfficer',
        'client',
        'weekendLeaves',
        'isForEmployees',
        'isSpecialHolidayAllowanceCalculation',
        'isGrossBonus',
      ];
      const hiddenList = [
        'eInvoice',
        'apiKey',
        'companyAdmin',
        'companyAdminReceiveHREmails',
        'type',
        'workFromHomeType',
        'mainCompany',
        'calculationMethodMeal',
        'mealCurrency',
        'calculationMethodWorkFromHome',
        'workFromHomeCurrency',
        'calculationMethodHoliday',
        'holidayCurrency',
        'travelExpenses',
        'travelExpCurrency',
        'salaryCalculationType',
        'holidayLeaveConfirmation',
        'sickLeaveUpTo30Days65Confirmation',
        'sickLeaveUpTo30Days100Confirmation',
        'paidLeaveConfirmation',
        'publicHolidayConfirmation',
        'overTime2',
        'bonus',
        'bonusCurrency',
        'otherIncome',
        'otherIncomeCurrency',
        'suspension',
        'suspensionCurrency',
        'otherExpenses',
        'otherExpensesCurrency',
        'isSector',
        'holidayDaysTracker',
        'weekendLeaves',
      ];

      setDisabledList(disabledList);
      setHiddenFieldsList(hiddenList);
      // this is used for buttons that client and Client officer can never see
      setCanUserUpdate(false);
    }

    if (userRole === 'eInvoiceOfficer') {
      const disabledList = [
        'name',
        'clientOfficer',
        'client',
        'eInvoice',
        'showCompanyLogoInDocs',
        'code',
        'pib',
        'authorizedPerson',
        'zip',
        'city',
        'address',
        'email',
        'phoneNumber',
        'eInvoiceOfficer',
        'fileAttachment',
        'file',
        'showCompanyLogoInDocs',
        'isSpecialHolidayAllowanceCalculation',
      ];
      const hiddenList = [
        'weekendLeaves',
        'isForEmployees',
        'isHr',
        'typeOfHr',
        'documentReturnType',
        'documentSeparateRequestResponse',
        'weekendLeaves',
        'isForEmployees',
        'ownershipStructure',
        'apiKey',
        'workTypes',
        'companyAdmin',
        'companyAdminReceiveHREmails',
        'type',
        'workFromHomeType',
        'mainCompany',
        'calculationMethodMeal',
        'mealCurrency',
        'calculationMethodWorkFromHome',
        'workFromHomeCurrency',
        'calculationMethodHoliday',
        'holidayCurrency',
        'travelExpenses',
        'travelExpCurrency',
        'salaryCalculationType',
        'holidayLeaveConfirmation',
        'sickLeaveUpTo30Days65Confirmation',
        'sickLeaveUpTo30Days100Confirmation',
        'paidLeaveConfirmation',
        'publicHolidayConfirmation',
        'overTime2',
        'bonus',
        'bonusCurrency',
        'otherIncome',
        'otherIncomeCurrency',
        'suspension',
        'suspensionCurrency',
        'otherExpenses',
        'otherExpensesCurrency',
        'isSector',
        'holidayDaysTracker',
        'weekendLeaves',
        'ownershipStructure',
        'companyLogo',
        'foreignWorkPermitTracker',
        'isSpecialHolidayAllowanceCalculation',
        'isGrossBonus',
      ];

      setDisabledList(disabledList);
      setHiddenFieldsList(hiddenList);
      // this is used for buttons that eInvoiceOfficer can never see
      setCanUserUpdate(false);
    }
  }, []);

  useEffect(() => {
    if (!isNew) {
      handleIsDisabledHidden(userRole);
    }
    if (isNew) {
      setCanUserUpdate(true);
    }
  }, [handleIsDisabledHidden, isNew, userRole]);

  if (!user.role.includes('admin')) {
    initialValues.type = 'branch';
    !companyType && setCompanyType('branch');
  } else {
    !companyType && setCompanyType('main');
  }

  let company = {
    ...initialValues,
    ...data,
    client: data?.client?.role?.includes('user') ? data?.client?._id : '',
    userOfficer: Array.isArray(data?.userOfficer)
      ? data?.userOfficer.filter((officer) => officer?.role?.includes('userOfficer')).map((officer) => officer._id)
      : [],
    isCompanyLogoChecked: companyLogoChecked,
    eInvoiceOfficer: iseInvoiceChecked ? data?.eInvoiceOfficer : '',
    type: data?.type ? data?.type : 'main',
    documentReturnType: data?.documentReturnType === 'word-pdf' ? 'word' : data?.documentReturnType,
    documentSeparateRequestResponse:
      data?.documentSeparateRequestResponse === undefined ? 'regular' : data?.documentSeparateRequestResponse,
  };

  const setPresignedUrl = async (companyLogo) => {
    const request = await Axios.post(`${SERVER_URL}/presign-url`, companyLogo, {
      headers: {
        Authorization: `Bearer ${currentuser.data.token}`,
        withCredentials: false,
      },
    });

    setFileList([request.data.logo]);
  };

  useEffect(() => {
    if (data && data.trackerStartDate) {
      setTrackerStartDate(moment(data.trackerStartDate));
    }

    if (data && data.companyLogo && data.companyLogo.location) {
      setPresignedUrl(data.companyLogo);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.client === null) {
      form.setFieldValue('client', null);
    }
    if (data && data.client) {
      form.setFieldValue('client', data.client._id);
    }

    if (data && data.bankAccount) {
      setAccountSections2({
        section1: data.bankAccount.substring(0, 3),
        section2: data.bankAccount.substring(3, 16),
        section3: data.bankAccount.substring(16, 18),
      });
      form.setFieldValue('bankAccount', data.bankAccount);
      setBankAccountSectionVisible(true);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.companyAdmin && data.companyAdmin.length) {
      const ids = data.companyAdmin.map((item) => item._id);
      form.setFieldsValue({
        companyAdmin: ids,
      });
    }
    if (data && data.userOfficer && data.userOfficer.length) {
      const ids = data.userOfficer.map((item) => item?._id);
      form.setFieldsValue({
        userOfficer: ids,
      });
    }
    if (data && data.userOfficer && data.userOfficer.length === 1 && data.userOfficer[0] === null) {
      form.setFieldsValue({
        userOfficer: null,
      });
    }
  }, [data]);

  const createAccounts = async () => {
    if (data.isHr === true) {
      try {
        await Axios.post(`${SERVER_URL}/companies/sync/${data._id}`);
        notification.success({
          message: 'All employee accounts with emails have been created.',
          placement: 'bottomRight',
        });
      } catch (err) {
        console.error('err', err);
        notification.error({
          message: 'Problem with creating the employee accounts. Please try again later.',
          placement: 'bottomRight',
        });
      }
    } else {
      notification.error({
        message: 'You have to have "HR Platform" checked if you want to create employee accounts for this company',
        placement: 'bottomRight',
      });
    }
  };

  const handleFormSubmission = (values, type) => {
    // this conditionally sets required property to bank account field
    // validation also activated if eInvoices not checked, if user enters some value in bankAccount field
    const overallError2 =
      accountSections2.section1 || accountSections2.section2 || accountSections2.section3
        ? getOverallValidationError2()
        : null;

    if (
      overallError2 &&
      (accountSections2.section1 ||
        accountSections2.section2 ||
        accountSections2.section3 ||
        (accountSections2.section1 && accountSections2.section1.length !== 3) ||
        (accountSections2.section1 && accountSections2.section3.length !== 2) ||
        (!/^\d+$/.test(accountSections2.section1) && accountSections2.section1) ||
        (!/^\d+$/.test(accountSections2.section3) && accountSections2.section3) ||
        (!/^\d+$/.test(accountSections2.section2) && accountSections2.section2))
    ) {
      setOverallErrorMessage2(overallError2);
      return;
    }

    if (!values.workTypes || values.workTypes.length < 1) {
      values.workTypes = [
        'Regular work',
        'Holiday leave',
        'Public holiday',
        'Sick leave up to 30 days 65%',
        'Sick leave more than 30 days 65%',
        'Maternity leave',
        'Paid leave',
        'Unemployed',
        'Work on public holiday',
      ];
    }

    values.otherIncomeCurrency = values.otherIncome ? values.otherIncomeCurrency : 'rsd';
    values.suspensionCurrency = values.suspensionCurrency ? values.suspensionCurrency : 'rsd';
    values.otherExpensesCurrency = values.otherExpensesCurrency ? values.otherExpensesCurrency : 'rsd';
    values.bonusCurrency = values.bonusCurrency ? values.bonusCurrency : 'rsd';
    values.eInvoiceOfficer = iseInvoiceChecked ? values.eInvoiceOfficer : undefined;
    values.companyAdmin = values.companyAdmin ? values.companyAdmin : [];
    values.trackerStartDate = trackerStartDate;
    values.weekendLeaves = weekendLeaves;
    values.isHrOfficerSelfApprove = isHrOfficerSelfApprove;
    values.foreignWorkPermitTracker = isForeignWorkPermitTracker;
    values.qrInEInvoices = qrInEInvoices;
    // Add values that will bring info on logo
    values.companyLogo = {
      typeOfFile: fileList.length ? fileList[0].fileType : undefined,
      location: fileList.length ? fileList[0].location : undefined,
      originalName: fileList.length ? fileList[0].name : undefined,
    };
    values.isForEmployees = isForEmployees;
    values.isGrossBonus = isGrossBonusChecked;
    values.companyAdminReceiveHREmails = companyAdminReceiveHREmails;
    values.isSpecialHolidayAllowanceCalculation = isSpecialHolidayAllowanceCalculationChecked;
    values.bankAccount = form.getFieldValue('bankAccount')
      ? form.getFieldValue('bankAccount')
      : `${accountSections2.section1}${accountSections2.section2}${accountSections2.section3}`;
    values.bankAccountsArray =
      (accounts && accounts.length && accounts.map((item) => `${item.section1}${item.section2}${item.section3}`)) || [];

    if (companyType === 'branch') {
      const mainCompany = companies && companies.filter((item) => item._id === values.mainCompany);
      values.calculationMethodMeal = mainCompany[0].calculationMethodMeal;
      values.mealCurrency = mainCompany[0].mealCurrency;
      values.calculationMethodHoliday = mainCompany[0].calculationMethodHoliday;
      values.holidayCurrency = mainCompany[0].holidayCurrency;
      values.calculationMethodWorkFromHome = mainCompany[0].calculationMethodWorkFromHome;
      values.workFromHomeCurrency = mainCompany[0].workFromHomeCurrency;
      values.travelExpenses = mainCompany[0].travelExpenses;
      values.travelExpCurrency = mainCompany[0].travelExpCurrency;
      values.bonusCurrency = mainCompany[0].bonusCurrency;
      values.bonus = mainCompany[0].bonus;
      values.isGrossBonus = mainCompany[0].isGrossBonus;
      values.isSpecialHolidayAllowanceCalculation = mainCompany[0].isSpecialHolidayAllowanceCalculation;
      values.workTypes = mainCompany[0].workTypes;
      values.overTime2 = mainCompany[0].overTime2;
      values.otherIncome = mainCompany[0].otherIncome;
      values.suspension = mainCompany[0].suspension;
      values.otherExpenses = mainCompany[0].otherExpenses;
      values.workFromHomeType = mainCompany[0].workFromHomeType;
      values.otherIncomeCurrency = values.otherIncome ? mainCompany[0].otherIncomeCurrency : 'rsd';
      values.suspensionCurrency = values.suspension ? mainCompany[0].suspensionCurrency : 'rsd';
      values.otherExpensesCurrency = values.otherExpenses ? mainCompany[0].otherExpensesCurrency : 'rsd';
      values.companyAdmin = values.companyAdmin ? values.companyAdmin : mainCompany[0].companyAdmin;
      values.companyAdminReceiveHREmails = companyAdminReceiveHREmails;
    }
    if (emails && emails.length !== 0) {
      values.emailReceiver = emails;
    }
    if (data && data.emailReceiver && emails.length === 0) {
      values.emailReceiver = [];
    }

    if (isNew) {
      createHandler(values, type);
    } else {
      updateHandler(values, type);
    }
    setValidationError2('');
    setOverallErrorMessage2('');

    notification.close(COMPANY_FORM_NOTIFICATION_KEY);
  };

  useEffect(() => {
    const { section1, section2, section3 } = accountSections2;
    if (section1 === '' && section2 === '' && section3 === '') {
      setValidationError2('');
      setOverallErrorMessage2('');
      form.setFieldValue('bankAccount', '');
    }
  }, [accountSections2]);

  const onFinish = (values) => {
    handleFormSubmission(values, 'company');
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  if (data) {
    if (!data.mealCurrency) {
      company.mealCurrency = 'rsd';
    }
    if (!data.holidayCurrency) {
      company.holidayCurrency = 'rsd';
    }
    if (!data.travelExpCurrency) {
      company.travelExpCurrency = 'rsd';
    }
    if (!data.otherIncomeCurrency) {
      company.otherIncomeCurrency = 'rsd';
    }
    if (!data.suspensionCurrency) {
      company.suspensionCurrency = 'rsd';
    }
    if (!data.otherExpensesCurrency) {
      company.otherExpensesCurrency = 'rsd';
    }

    if (!data.bonusCurrency) {
      company.bonusCurrency = 'rsd';
    }
  }

  const validateEmail = (value) => {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const addEmail = () => {
    const value = form.getFieldValue('recipient');
    const validEmail = validateEmail(value);
    if (validEmail) {
      if (!emails.includes(value)) {
        setEmails((prevState) => [...prevState, value]);
      } else {
        notification.error({
          message: 'This email already exists',
          placement: 'bottomRight',
        });
      }
      form.setFieldsValue({
        recipient: undefined,
      });
    } else {
      notification.error({
        message: 'Email is not valid',
        placement: 'bottomRight',
      });
    }
  };

  const changeEmail = (index, value) => {
    let emailArray = emails;
    if (value) {
      emailArray[index] = value[index];
      const validEmail = validateEmail(emailArray[index]);
      if (validEmail) {
        setEmails((prevState) => [...emailArray]);
        notification.success({
          message: 'Email is edited',
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'New email is not valid',
          placement: 'bottomRight',
        });
      }
    }
  };

  const removeEmail = (index) => {
    try {
      let emailArray = emails;
      emailArray.splice(index, 1);
      setEmails((prevState) => [...emailArray]);
      notification.success({
        message: 'Email is deleted',
        placement: 'bottomRight',
      });
    } catch {
      notification.error({
        message: 'Problem with deleting email',
        placement: 'bottomRight',
      });
    }
  };

  const otherIncomeHandler = (e) => {
    setIsOtherIncomeChecked(e.target.checked);
  };
  const suspensionHandler = (e) => {
    setIsSuspensionChecked(e.target.checked);
  };
  const otherExpensesHandler = (e) => {
    setIsOtherExpensesChecked(e.target.checked);
  };

  const specialHolidayAllowanceCalculationHandler = (e) => {
    setIsSpecialHolidayAllowanceCalculationChecked(e.target.checked);
  };

  const bonusHandler = (e) => {
    setIsBonusChecked(e.target.checked);
  };
  const grossBonusHandler = (e) => {
    setIsGrossBonusChecked(e.target.checked);
  };

  const sectorsHandler = (e) => {
    setIsSectorsChecked(e.target.checked);
  };

  const sectorsSelfApproveHandler = (e) => {
    setIsHrOfficerSelfApprove(e.target.checked);
  };

  const holidayDaysTracker = (e) => {
    setIsHolidayDaysTracker(e.target.checked);
  };

  const foreignWorkPermitTracker = (e) => {
    setIsForeignWorkPermitTracker(e.target.checked);
  };

  const weekendLeavesTracker = (e) => {
    setWeekendLeaves(e.target.checked);
  };

  useEffect(() => {
    setIsOtherIncomeChecked(isOtherIncomeChecked === undefined ? true : isOtherIncomeChecked);
    setIsSuspensionChecked(isSuspensionChecked === undefined ? true : isSuspensionChecked);
    setIsOtherExpensesChecked(isOtherExpensesChecked === undefined ? true : isOtherExpensesChecked);
    setIsBonusChecked(isBonusChecked === undefined ? true : isBonusChecked);
    setIsSectorsChecked(isSectorsChecked === undefined ? true : isSectorsChecked);
    setIsHrOfficerSelfApprove(isHrOfficerSelfApprove === undefined ? true : isHrOfficerSelfApprove);
    setIsSpecialHolidayAllowanceCalculationChecked(
      isSpecialHolidayAllowanceCalculationChecked === undefined ? false : isSpecialHolidayAllowanceCalculationChecked,
    );
  }, [
    isOtherIncomeChecked,
    isSuspensionChecked,
    isOtherExpensesChecked,
    isSectorsChecked,
    isBonusChecked,
    isHrOfficerSelfApprove,
    isSpecialHolidayAllowanceCalculationChecked,
  ]);

  const validateSections2 = (accountSections2) => {
    const { section1, section2, section3 } = accountSections2;
    const errors = {};

    // Validation logic for each section
    if (!section1 || section1.length !== 3 || !/^\d+$/.test(section1)) {
      errors.section1 = 'Section 1 should be a 3-digit number';
    } else {
      errors.section1 = '';
    }

    if (!section2 || section2.length !== 13 || !/^\d+$/.test(section2)) {
      if (section2.length !== 13) {
        let paddedValue = section2;
        const numberOfZeros = 13 - section2.length;
        const zeros = '0'.repeat(numberOfZeros);
        paddedValue = zeros + section2;
        setAccountSections2({
          section1: section1,
          section2: paddedValue,
          section3: section3,
        });
        form.setFieldValue('bankAccount', `${accountSections2.section1}${paddedValue}${accountSections2.section3}`);
      } else errors.section2 = 'Section 2 should be a 13-digit number';
    } else {
      errors.section2 = '';
    }

    if (!section3 || section3.length !== 2 || !/^\d+$/.test(section3)) {
      errors.section3 = 'Section 3 should be a 2-digit number';
    } else {
      errors.section3 = '';
    }

    if (errors.section1 === '' && errors.section2 === '' && errors.section3 === '') {
      form.setFieldValue(
        'bankAccount',
        `${accountSections2.section1}${accountSections2.section2}${accountSections2.section3}`,
      );
    }

    return errors;
  };

  const validateAllSections2 = () => {
    const errors = validateSections2(accountSections2);

    setValidationErrors2({ ...errors });
    return errors;
  };

  const getOverallValidationError2 = () => {
    const errors = validateAllSections2();
    const errorsArray = Object.values({ ...errors });
    const hasErrors = errorsArray.some((error) => error !== '');

    return hasErrors ? 'Please enter a valid account number in the format xxx-xxxxxxxxxxxxx-xx' : '';
  };

  const handleSectionChange2 = (sectionIndex, value) => {
    setAccountSections2((prevAccountSections2) => ({
      ...prevAccountSections2,
      [`section${sectionIndex + 1}`]: value,
    }));
  };

  const handleFocusTypeOfWork = () => {
    if (typeOfWorkRef.current) {
      const offsetTop = typeOfWorkRef.current.offsetTop;
      const bodyEl = document.querySelector('body');
      const offset = -170;

      bodyEl.scrollTo({
        top: offsetTop + offset,
        behavior: 'instant',
      });
    }
  };

  const editOwnershipStructure = () => {
    setCompanyOwnershipDrawer(true);
  };

  const onClose = () => {
    setCompanyOwnershipDrawer(false);
  };

  const handleDrawerSubmit = (data) => {
    form.setFieldsValue({
      ownershipStructure: data,
    });

    setCompanyOwnershipDrawer(false);

    notification.info({
      message: 'You have pending changes which need to be confirmed by Submit button.',
      placement: 'bottomRight',
      duration: 0,
      key: COMPANY_FORM_NOTIFICATION_KEY,
    });
  };

  const handleDeleteAccount = async (index) => {
    const newAccounts = accounts.filter((_, i) => i !== index);
    setAccounts(newAccounts);
    await Axios.put(`${SERVER_URL}/companies-delete-bank-account/${data._id}`, newAccounts, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${user?.data?.token}` },
    })
      .then((res) => {
        if (res.data.bankAccountsArray && res.data.bankAccountsArray.length) {
          const array = res.data?.bankAccountsArray?.map((item) => {
            return {
              id: Date.now(),
              section1: item.substring(0, 3) || '',
              section2: item.substring(3, 16) || '',
              section3: item.substring(16, 18) || '',
              isDefault: false,
            };
          });
          setAccounts(array);
        } else setAccounts([]);

        notification.success({
          message: 'Default value for Bank account field changed!.',
          placement: 'bottomRight',
        });
      })
      .catch((error) => {
        console.error('error', error);
        notification.error({
          message: 'Something went wrong with updating Bank accounts! Please contact administrator..',
          placement: 'bottomRight',
        });
      });
  };

  const handleSectionChangeAdded = (accountIndex, sectionIndex, value) => {
    const newAccounts = [...accounts];
    newAccounts[accountIndex][`section${sectionIndex + 1}`] = value;
    setAccounts(newAccounts);
  };

  const handleAddAccount = () => {
    setAccounts([...accounts, { id: Date.now(), section1: '', section2: '', section3: '', isDefault: false }]);
  };

  const handleDefaultChange = async (index) => {
    const newAccounts = accounts.map((account, i) => ({
      ...account,
      isDefault: i === index,
    }));
    setAccounts(newAccounts);

    await Axios.put(`${SERVER_URL}/companies-update-bank-accounts/${data._id}`, newAccounts, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${user?.data?.token}` },
    })
      .then((res) => {
        if (res.data.newBankAccount) {
          setAccountSections2({
            section1: res.data.newBankAccount.substring(0, 3),
            section2: res.data.newBankAccount.substring(3, 16),
            section3: res.data.newBankAccount.substring(16, 18),
          });
          form.setFieldValue('bankAccount', res.data.newBankAccount);
          const array = res.data.bankAccountsArray.map((item) => {
            return {
              id: Date.now(),
              section1: item.substring(0, 3) || '',
              section2: item.substring(3, 16) || '',
              section3: item.substring(16, 18) || '',
              isDefault: false,
            };
          });
          setAccounts([]);
          setAccounts(array);
        }

        notification.success({
          message: 'Default value for Bank account field changed!.',
          placement: 'bottomRight',
        });
      })
      .catch((error) => {
        console.error('error', error);
        notification.error({
          message: 'Something went wrong with updating default value for Bank account! Please contact administrator..',
          placement: 'bottomRight',
        });
      });
  };

  const isObjectFullyFilled = (obj) => {
    return Object.values(obj).every((value) => value.trim() !== '');
  };

  useEffect(() => {
    if (isObjectFullyFilled(accountSections2)) setBankAccountSectionVisible(true);
  }, [accountSections2]);

  return (
    <div>
      <div className='companyStyleDashboard '>
        <div className='companyStyleDashboard card-wrapper' style={{ marginTop: '10px' }}>
          {/* eslint-disable-next-line no-useless-concat */}
          <div className='companyStyleDashboard companyForm'>
            <Card title={`${isNew ? 'NEW COMPANY' : 'COMPANY DETAILS'} `} bordered={false}>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='General data' key='1'>
                  {!result && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Form
                        name='basic'
                        initialValues={company}
                        onFinish={(values) => onFinish(values, isNew)}
                        onFinishFailed={onFinishFailed}
                        layout='horizontal'
                        form={form}
                        ref={formRef}
                        style={{ marginTop: '15px', width: '70%' }}
                      >
                        <div
                          style={{
                            backgroundColor: '#f7f7f7',
                            padding: '20px 0 0 20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            alignItems: 'flex-start',
                            marginLeft: 'auto',
                            marginBottom: '30px',
                          }}
                        >
                          <Form.Item
                            className='custom-label'
                            valuePropName='checked'
                            label='HR Platform'
                            name='isHr'
                            hidden={hiddenFieldsList.includes('isHr')}
                          >
                            <Checkbox
                              style={{ marginLeft: '10px' }}
                              disabled={disabledFieldsList.includes('isHr')}
                              onChange={(e) => setIsHrChecked(e.target.checked)}
                            />
                          </Form.Item>
                          {isHrChecked && (
                            <>
                              <Form.Item
                                className='custom-label'
                                label='HR Documents'
                                name='typeOfHr'
                                hidden={hiddenFieldsList.includes('typeOfHr')}
                              >
                                <Radio.Group
                                  disabled={disabledFieldsList.includes('typeOfHr')}
                                  style={{ marginLeft: '10px' }}
                                >
                                  <Radio defaultChecked value='serbian'>
                                    Serbian
                                  </Radio>
                                  <Radio value='bilingual'>Bilingual</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                className='custom-label'
                                label='HR Documents Types'
                                name='documentReturnType'
                                hidden={hiddenFieldsList.includes('documentReturnType')}
                              >
                                <Radio.Group
                                  defaultValue='word'
                                  disabled={disabledFieldsList.includes('documentReturnType')}
                                  style={{ marginLeft: '10px' }}
                                >
                                  <Radio defaultChecked value='word'>
                                    Word
                                  </Radio>
                                  <Radio value='pdf'>PDF</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                className='custom-label'
                                label='Separated Request Docs'
                                name='documentSeparateRequestResponse'
                                hidden={hiddenFieldsList.includes('documentSeparateRequestResponse')}
                              >
                                <Radio.Group
                                  defaultValue='regular'
                                  style={{ marginLeft: '10px' }}
                                  disabled={disabledFieldsList.includes('documentSeparateRequestResponse')}
                                >
                                  <Radio defaultChecked value='regular'>
                                    Regular
                                  </Radio>
                                  <Radio value='separated'>Separated</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                className='custom-label'
                                valuePropName='checked'
                                label='Leaves Over the Weekend'
                                name='weekendLeaves'
                                hidden={hiddenFieldsList.includes('weekendLeaves')}
                              >
                                <Checkbox
                                  style={{ marginLeft: '10px' }}
                                  disabled={disabledFieldsList.includes('weekendLeaves')}
                                  onChange={weekendLeavesTracker}
                                />
                              </Form.Item>
                              <Form.Item
                                className='custom-label'
                                valuePropName='checked'
                                label='Is for employees'
                                hidden={hiddenFieldsList.includes('isForEmployees')}
                              >
                                <Checkbox
                                  style={{ marginLeft: '10px', marginTop: '5px' }}
                                  disabled={disabledFieldsList.includes('isForEmployees')}
                                  checked={isForEmployees}
                                  onChange={(e) => setIsForEmployees(e.target.checked)}
                                />
                                <Tooltip
                                  title={
                                    <span style={{ color: '#333' }}>
                                      This will allow/disallow access to the platform for employees
                                    </span>
                                  }
                                  color='#fff'
                                  placement='top'
                                  autoAdjustOverflow={true}
                                >
                                  <QuestionCircleOutlined
                                    style={{
                                      marginLeft: '7px',
                                      marginTop: '1px',
                                      color: '#b1b1b1',
                                      cursor: 'pointer',
                                      marginBottom: '24px',
                                    }}
                                  />
                                </Tooltip>
                              </Form.Item>
                            </>
                          )}
                          <Form.Item
                            className='custom-label'
                            valuePropName='checked'
                            label='E-Invoices Platform'
                            name='eInvoice'
                            hidden={hiddenFieldsList.includes('eInvoice')}
                            style={{ marginTop: '-12px' }}
                          >
                            <Checkbox
                              style={{ marginLeft: '10px' }}
                              disabled={disabledFieldsList.includes('eInvoice')}
                              onChange={(e) => setIseInvoiceChecked(e.target.checked)}
                            />
                          </Form.Item>
                          {iseInvoiceChecked && (
                            <Form.Item
                              className='custom-label'
                              valuePropName='checked'
                              name='qrInEInvoices'
                              label='QR code in E-Invoices'
                              hidden={hiddenFieldsList.includes('qrCodeEInvoices')}
                            >
                              <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={disabledFieldsList.includes('qrCodeEInvoices')}
                                onChange={(e) => setQrInEInvoices(e.target.checked)}
                              />
                            </Form.Item>
                          )}
                          {companyId ? (
                            <>
                              <Form.Item
                                name='fileAttachment'
                                label='Company Logo'
                                className='attach custom-label'
                                tooltip={{
                                  title: 'Supported file types are PNG, JPEG with max size of 5MB.',
                                  color: 'white',
                                  overlayInnerStyle: {
                                    color: '#333',
                                    cursor: 'pointer',
                                  },
                                }}
                              >
                                <Upload
                                  accept='image/png, image/jpeg, image/svg+xml, image/gif'
                                  customRequest={customRequest}
                                  listType='picture-card'
                                  name='file'
                                  fileList={fileList}
                                  headers={{
                                    Authorization: `Bearer ${currentuser.data.token}`,
                                    uri: 'static/image/',
                                    timestamp: Date.now().toString(),
                                  }}
                                  maxCount={1}
                                  multiple={false}
                                  onPreview={handlePreview}
                                  beforeUpload={handleBeforeUpload}
                                  onChange={handleChange}
                                  disabled={disabledFieldsList.includes('file')}
                                >
                                  {fileList.length >= 2 ? null : uploadButton}
                                </Upload>
                                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                  <img
                                    alt='example'
                                    style={{
                                      width: '100%',
                                    }}
                                    src={previewImage}
                                  />
                                </Modal>
                              </Form.Item>
                              {fileList.length ? (
                                <Form.Item
                                  className='custom-label'
                                  valuePropName='checked'
                                  label='Show company logo in documents'
                                  name='showCompanyLogoInDocs'
                                  hidden={!companyId}
                                >
                                  <Checkbox
                                    style={{ marginLeft: '10px' }}
                                    disabled={!fileList.length || disabledFieldsList.includes('showCompanyLogoInDocs')}
                                    onChange={(e) => setCompanyLogoChecked(e.target.checked)}
                                  />
                                </Form.Item>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                        {iseInvoiceChecked && (
                          <Form.Item
                            name='apiKey'
                            label='API key'
                            labelCol={{ span: 6 }}
                            labelAlign='left'
                            hidden={hiddenFieldsList.includes('apiKey')}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter API key!',
                              },
                            ]}
                          >
                            <Input disabled={disabledFieldsList.includes('apiKey')} />
                          </Form.Item>
                        )}
                        <Form.Item
                          label='Registration no. (MB)'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          name='code'
                          hidden={hiddenFieldsList.includes('code')}
                        >
                          <Input disabled={disabledFieldsList.includes('code')} />
                        </Form.Item>
                        <Form.Item
                          label='Name of company'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          disabled={true}
                          name='name'
                          hidden={hiddenFieldsList.includes('name')}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter name of company!',
                            },
                          ]}
                        >
                          <Input disabled={disabledFieldsList.includes('name')} />
                        </Form.Item>
                        <Form.Item
                          label='TIN (PIB)'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          name='pib'
                          hidden={hiddenFieldsList.includes('pib')}
                          rules={[
                            {
                              required: true,
                              message: 'Please, enter your TIN/VAT/PIB number!',
                            },
                          ]}
                        >
                          <Input disabled={disabledFieldsList.includes('pib')} />
                        </Form.Item>
                        <Form.Item
                          label='Authorized persons'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          name='authorizedPersons'
                          hidden={hiddenFieldsList.includes('authorizedPersons')}
                        >
                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                            {data?.ownershipStructure?.some((person) => person.type === 'authorized') ? (
                              data.ownershipStructure
                                .filter((person) => person.type === 'authorized')
                                .map((person) => (
                                  <Tag
                                    key={person._id}
                                    style={{
                                      margin: '2px',
                                      fontSize: '14px',
                                      padding: '4px 8px',
                                      borderRadius: '8px',
                                    }}
                                  >
                                    {person.name}
                                  </Tag>
                                ))
                            ) : (
                              <Tag
                                style={{
                                  margin: '2px',
                                  fontSize: '14px',
                                  padding: '4px 8px',
                                  borderRadius: '8px',
                                  background: '#f0f8ff',
                                  border: '1px dashed #1890ff',
                                  color: '#1890ff',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div style={{ textAlign: 'center' }}>
                                  <p style={{ margin: 0 }}>Authorize someone in below section ⬇️</p>
                                </div>
                              </Tag>
                            )}
                          </div>
                        </Form.Item>

                        <Form.Item
                          label='Ownership structure'
                          name='ownershipStructure'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          hidden={hiddenFieldsList.includes('ownershipStructure')}
                          style={{ marginTop: '10px' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              fontSize: '16px',
                              color: '#1890ff',
                              textDecoration: 'underline',
                            }}
                            onClick={editOwnershipStructure}
                          >
                            Click to edit Ownership and Authorization Structure ⬅️
                          </div>
                        </Form.Item>

                        <Form.Item
                          label='Postal number'
                          name='zip'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          hidden={hiddenFieldsList.includes('zip')}
                        >
                          <Input disabled={disabledFieldsList.includes('zip')} />
                        </Form.Item>
                        <Form.Item
                          label='City'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          name='city'
                          hidden={hiddenFieldsList.includes('city')}
                        >
                          <Input disabled={disabledFieldsList.includes('city')} />
                        </Form.Item>
                        <Form.Item
                          label='Address'
                          name='address'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          hidden={disabledFieldsList.includes('address')}
                        >
                          <Input disabled={disabledFieldsList.includes('address')} />
                        </Form.Item>
                        <Form.Item
                          label='Email'
                          name='email'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          hidden={disabledFieldsList.includes('email')}
                        >
                          <Input disabled={disabledFieldsList.includes('email')} />
                        </Form.Item>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: bankAccountSectionVisible ? '#f7f7f7' : '',
                            padding: bankAccountSectionVisible ? '20px 10px 10px 5px' : '',
                            borderRadius: bankAccountSectionVisible ? '8px' : '',
                            boxShadow: bankAccountSectionVisible ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '',
                            marginBottom: bankAccountSectionVisible ? '30px' : '',
                          }}
                        >
                          <Form.Item
                            label={
                              iseInvoiceChecked ? (
                                <span>
                                  <span style={{ color: 'red' }}>*</span>
                                  Bank Account - <strong>default</strong>
                                </span>
                              ) : (
                                'Bank Account - default'
                              )
                            }
                            name='bankAccount'
                            labelCol={{ span: 6 }}
                            labelAlign='left'
                            validateStatus={validationError2 ? 'error' : ''}
                            help={validationError2}
                            hidden={hiddenFieldsList.includes('bankAccount')}
                          >
                            <Input.Group compact style={{ fontSize: '14px' }}>
                              <Input
                                style={{ width: '20%', border: '1px solid green' }}
                                placeholder='xxx'
                                maxLength={3}
                                value={accountSections2.section1}
                                onChange={(e) => handleSectionChange2(0, e.target.value)}
                                disabled={disabledFieldsList.includes('bankAccount')}
                              />
                              <Input
                                style={{ width: '60%', border: '1px solid green' }}
                                placeholder='xxxxxxxxxxxxx'
                                maxLength={13}
                                value={accountSections2.section2}
                                onChange={(e) => handleSectionChange2(1, e.target.value)}
                                disabled={disabledFieldsList.includes('bankAccount')}
                              />
                              <Input
                                style={{ width: '20%', border: '1px solid green' }}
                                placeholder='xx'
                                maxLength={2}
                                value={accountSections2.section3}
                                onChange={(e) => handleSectionChange2(2, e.target.value)}
                                disabled={disabledFieldsList.includes('bankAccount')}
                              />
                            </Input.Group>
                          </Form.Item>

                          {bankAccountSectionVisible &&
                            accounts.length > 0 &&
                            accounts.map((account, index) => (
                              <Form.Item
                                key={account.id}
                                label={<span>Bank Account {index + 1}</span>}
                                name={`bankAccount_${index}`}
                                labelCol={{ span: 6 }}
                                labelAlign='left'
                                validateStatus={validationError2 ? 'error' : ''}
                                help={validationError2}
                                hidden={hiddenFieldsList.includes('bankAccount')}
                              >
                                <Input.Group compact style={{ fontSize: '14px' }}>
                                  <Radio
                                    checked={account.isDefault}
                                    onChange={() => handleDefaultChange(index)}
                                    style={{ marginRight: '10px' }}
                                  />
                                  <Input
                                    style={{ width: '20%' }}
                                    placeholder='xxx'
                                    maxLength={3}
                                    value={account.section1}
                                    onChange={(e) => handleSectionChangeAdded(index, 0, e.target.value)}
                                    disabled={disabledFieldsList.includes('bankAccount')}
                                  />
                                  <Input
                                    style={{ width: '40%' }}
                                    placeholder='xxxxxxxxxxxxx'
                                    maxLength={13}
                                    value={account.section2}
                                    onChange={(e) => handleSectionChangeAdded(index, 1, e.target.value)}
                                    disabled={disabledFieldsList.includes('bankAccount')}
                                  />
                                  <Input
                                    style={{ width: '20%' }}
                                    placeholder='xx'
                                    maxLength={2}
                                    value={account.section3}
                                    onChange={(e) => handleSectionChangeAdded(index, 2, e.target.value)}
                                    disabled={disabledFieldsList.includes('bankAccount')}
                                  />
                                  <Button
                                    type='primary'
                                    danger
                                    onClick={() => handleDeleteAccount(index)}
                                    style={{
                                      marginLeft: '10px',
                                      marginTop: '5px',
                                      float: 'right',
                                      fontSize: '0.6em',
                                      padding: '4px 4px',
                                      lineHeight: '1',
                                      height: '24px',
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Input.Group>
                                {overallErrorMessage2 && <div style={{ color: 'red' }}>{overallErrorMessage2}</div>}
                              </Form.Item>
                            ))}

                          <div
                            style={{
                              display: bankAccountSectionVisible ? 'flex' : 'none',
                              flexDirection: 'row',
                            }}
                          >
                            <Button
                              type='dashed'
                              onClick={handleAddAccount}
                              style={{ marginBottom: '10px', width: '20%', borderColor: 'green' }}
                            >
                              Add Bank Account
                            </Button>
                            <Tooltip
                              title='You can add multiple bank accounts, but only the checked one will be used as the default.'
                              color='#b1b1b1'
                              placement='top'
                              autoAdjustOverflow={true}
                            >
                              <QuestionCircleOutlined
                                style={{
                                  color: '#b1b1b1',
                                  cursor: 'pointer',
                                  marginBottom: '15px',
                                  marginLeft: '5px',
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>

                        <Form.Item
                          label='Phone Number'
                          name='phoneNumber'
                          labelCol={{ span: 6 }}
                          labelAlign='left'
                          hidden={hiddenFieldsList.includes('phoneNumber')}
                          rules={
                            iseInvoiceChecked
                              ? [
                                  {
                                    required: true,
                                    message: 'Please enter the company`s phone number!',
                                  },
                                ]
                              : ''
                          }
                        >
                          <Input disabled={disabledFieldsList.includes('phoneNumber')} />
                        </Form.Item>
                        {user && (user.role.includes('admin') || user.role.includes('SuperAdmin')) && (
                          <>
                            <Form.Item
                              label='Client'
                              labelAlign='left'
                              labelCol={{ span: 6 }}
                              name='client'
                              hidden={hiddenFieldsList.includes('client')}
                            >
                              <Select
                                showSearch
                                optionFilterProp='children'
                                filterOption={(input, option) => {
                                  const children = Array.isArray(option.children) ? option.children : [option.children];
                                  return children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                                disabled={disabledFieldsList.includes('client')}
                              >
                                <Option key='none' value={null}>
                                  None
                                </Option>
                                {clients &&
                                  clients.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                      {item.firstName} {item.lastName}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label='Client Officer'
                              labelAlign='left'
                              labelCol={{ span: 6 }}
                              name='userOfficer'
                              hidden={hiddenFieldsList.includes('userOfficer')}
                            >
                              <Select
                                mode='multiple'
                                allowClear
                                showSearch
                                optionFilterProp='children'
                                disabled={disabledFieldsList.includes('client')}
                                filterOption={(input, option) => {
                                  const children = Array.isArray(option.children) ? option.children : [option.children];
                                  return children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                              >
                                <Option key='none' value={null}>
                                  None
                                </Option>
                                {userOfficers &&
                                  userOfficers.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                      {item.firstName} {item.lastName}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </>
                        )}
                        {iseInvoiceChecked && (
                          <Form.Item
                            name='eInvoiceOfficer'
                            label='E-invoice Officer'
                            labelCol={{ span: 6 }}
                            labelAlign='left'
                            hidden={hiddenFieldsList.includes('eInvoiceOfficer')}
                            rules={
                              iseInvoiceChecked &&
                              form.getFieldValue('eInvoiceOfficer') !== null &&
                              !form.getFieldValue('eInvoiceOfficer')
                                ? [
                                    {
                                      required: true,
                                      message: 'Please select E-Invoice officer or select None!',
                                    },
                                  ]
                                : ''
                            }
                          >
                            <Select
                              showSearch
                              allowClear
                              mode='multiple'
                              optionFilterProp='children'
                              filterOption={(input, option) => {
                                // option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                const children = Array.isArray(option.children) ? option.children : [option.children];
                                return children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                              }}
                              disabled={disabledFieldsList.includes('eInvoiceOfficer')}
                              defaultValue='None'
                            >
                              <Option value={null}>None</Option>
                              {einvoiceOfficers &&
                                einvoiceOfficers.map((item, index) => (
                                  <Option key={index} value={item._id}>
                                    {item.firstName} {item.lastName}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        )}

                        <Form.Item
                          label='Company administrators'
                          labelAlign='left'
                          labelCol={{ span: 6 }}
                          name='companyAdmin'
                          hidden={hiddenFieldsList.includes('companyAdmin')}
                        >
                          <Select
                            mode='multiple'
                            allowClear
                            showSearch
                            optionFilterProp='children'
                            disabled={disabledFieldsList.includes('companyAdmin')}
                            filterOption={(input, option) =>
                              option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {admins &&
                              admins.map((item, index) => (
                                <Option key={index} value={item._id}>
                                  {item.firstName} {item.lastName}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name='companyAdminReceiveHREmails'
                          label='Company admins receive HR emails'
                          labelCol={{ span: 6 }}
                          labelAlign='left'
                          hidden={hiddenFieldsList.includes('companyAdminReceiveHREmails')}
                        >
                          <Radio.Group
                            onChange={(event) => setCompanyAdminReceiveHREmails(event.target.value)}
                            disabled={disabledFieldsList.includes('companyAdminReceiveHREmails')}
                          >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0' }} />
                        <Form.Item
                          name='type'
                          label='Company type'
                          labelCol={{ span: 6 }}
                          labelAlign='left'
                          hidden={hiddenFieldsList.includes('type')}
                        >
                          <Radio.Group
                            onChange={(event) => setCompanyType(event.target.value)}
                            disabled={disabledFieldsList.includes('type')}
                          >
                            {(user.role.includes('admin') ||
                              user.role.includes('SuperAdmin') ||
                              user.role.includes('user')) && <Radio value='main'>Main</Radio>}
                            <Radio value='branch'>Branch</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name='workFromHomeType'
                          label='Work from home'
                          labelCol={{ span: 6 }}
                          labelAlign='left'
                          hidden={hiddenFieldsList.includes('workFromHomeType')}
                        >
                          <Radio.Group
                            onChange={(event) => setWorkFromHomeType(event.target.value)}
                            disabled={disabledFieldsList.includes('workFromHomeType')}
                          >
                            {(user.role.includes('admin') ||
                              user.role.includes('SuperAdmin') ||
                              user.role.includes('user')) && <Radio value='1'>1</Radio>}
                            <Radio value='2'>2</Radio> && <Radio value='none'>none</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {companyType === 'branch' && (
                          <Form.Item
                            label='Main company'
                            name='mainCompany'
                            hidden={hiddenFieldsList.includes('mainCompany')}
                            rules={[
                              {
                                required: true,
                                message: 'Please choose main company!',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={disabledFieldsList.includes('mainCompany')}
                            >
                              {(user.role.includes('admin') || user.role.includes('SuperAdmin')) &&
                                companies &&
                                companies
                                  .filter((item) => !item.mainCompany)
                                  .map((item) => <Option value={item._id}> {item.name}</Option>)}
                              {!user.role.includes('admin') &&
                                !user.role.includes('SuperAdmin') &&
                                companies &&
                                companies
                                  .filter((item) => !item.mainCompany && item.client._id === user.id)
                                  .map((item) => <Option value={item._id}> {item.name}</Option>)}
                            </Select>
                          </Form.Item>
                        )}
                        {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                        {companyType === 'main' && (
                          <div>
                            <Form.Item
                              labelCol={{ span: 12 }}
                              labelAlign='left'
                              label='Method for calculation of meal allowance'
                              name='calculationMethodMeal'
                              hidden={hiddenFieldsList.includes('calculationMethodMeal')}
                              rules={[
                                {
                                  required: true,
                                  message: 'Choose method for calculation of meal allowance!',
                                },
                              ]}
                            >
                              <Select disabled={disabledFieldsList.includes('calculationMethodMeal')}>
                                <Option value='automatic'>Per month (regular work)</Option>
                                <Option value='manual'>Per month (all days of work)</Option>
                                <Option value='monthlyFee'>Per month (excluding sick leave)</Option>
                                <Option value='perDay'>Per day (regular work)</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name='mealCurrency'
                              label='Meal allowance currency'
                              labelCol={{ span: 12 }}
                              labelAlign='left'
                              hidden={hiddenFieldsList.includes('mealCurrency')}
                            >
                              <Select disabled={disabledFieldsList.includes('mealCurrency')}>
                                <Option value='rsd'>RSD</Option>
                                <Option value='eur'>EUR</Option>
                                <Option value='usd'>USD</Option>
                                <Option value='gbp'>GBP</Option>
                                <Option value='rub'>RUB</Option>
                              </Select>
                            </Form.Item>

                            {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}

                            {(workFromHomeType === '1' || workFromHomeType === '2') && (
                              <div>
                                <Form.Item
                                  labelCol={{ span: 12 }}
                                  labelAlign='left'
                                  label='Method for calculation of work from home'
                                  name='calculationMethodWorkFromHome'
                                  hidden={hiddenFieldsList.includes('calculationMethodWorkFromHome')}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Choose method for calculation of work from home!',
                                    },
                                  ]}
                                >
                                  <Select disabled={disabledFieldsList.includes('calculationMethodWorkFromHome')}>
                                    <Option value='automatic'>Per month (regular work)</Option>
                                    <Option value='manual'>Per month (all days)</Option>
                                    <Option value='fixed'> Per month (fixed defined value)</Option>
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  name='workFromHomeCurrency'
                                  hidden={hiddenFieldsList.includes('workFromHomeCurrency')}
                                  label='Work from home currency'
                                  labelCol={{ span: 12 }}
                                  labelAlign='left'
                                >
                                  <Select disabled={disabledFieldsList.includes('workFromHomeCurrency')}>
                                    <Option value='rsd'>RSD</Option>
                                    <Option value='eur'>EUR</Option>
                                    <Option value='usd'>USD</Option>
                                    <Option value='gbp'>GBP</Option>
                                    <Option value='rub'>RUB</Option>
                                  </Select>
                                </Form.Item>
                                {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                              </div>
                            )}

                            <Form.Item
                              labelCol={{ span: 12 }}
                              labelAlign='left'
                              label='Method for calculation of holiday allowance'
                              name='calculationMethodHoliday'
                              hidden={hiddenFieldsList.includes('calculationMethodHoliday')}
                              rules={[
                                {
                                  required: true,
                                  message: 'Choose method for calculation of holliday allowance!',
                                },
                              ]}
                            >
                              <Select disabled={disabledFieldsList.includes('calculationMethodHoliday')}>
                                <Option value='automatic'>Per month (regular work)</Option>
                                <Option value='manual'>Per month (all days of work)</Option>
                                <Option value='perDay'>Per day (holiday leave)</Option>
                                <Option value='monthlyFee'>Per month (excluding sick leave)</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name='holidayCurrency'
                              label='Holiday allowance currency'
                              labelCol={{ span: 12 }}
                              labelAlign='left'
                              hidden={hiddenFieldsList.includes('holidayCurrency')}
                            >
                              <Select disabled={disabledFieldsList.includes('holidayCurrency')}>
                                <Option value='rsd'>RSD</Option>
                                <Option value='eur'>EUR</Option>
                                <Option value='usd'>USD</Option>
                                <Option value='gbp'>GBP</Option>
                                <Option value='rub'>RUB</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name='isSpecialHolidayAllowanceCalculation'
                              hidden={hiddenFieldsList.includes('isSpecialHolidayAllowanceCalculation')}
                              valuePropName='checked'
                              style={{ textAlign: 'start' }}
                            >
                              <Checkbox
                                disabled={disabledFieldsList.includes('isSpecialHolidayAllowanceCalculation')}
                                onChange={specialHolidayAllowanceCalculationHandler}
                                checked={isSpecialHolidayAllowanceCalculationChecked}
                              >
                                Special method of holiday leave allowances calculation for employee
                              </Checkbox>

                              <Tooltip
                                title={
                                  <span style={{ color: '#333' }}>
                                    Selecting this field will allow employees in the company to automatically receive a
                                    holiday leave allowance each month dynamically, as 1/12 of the national minimum wage
                                    for that month.
                                  </span>
                                }
                                color='#fff'
                                placement='top'
                                autoAdjustOverflow={true}
                              >
                                <QuestionCircleOutlined
                                  style={{
                                    marginTop: '2px',
                                    color: '#b1b1b1',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Tooltip>
                            </Form.Item>
                            {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                            <Form.Item
                              labelCol={{ span: 12 }}
                              labelAlign='left'
                              label='Method for travel expenses'
                              name='travelExpenses'
                              hidden={hiddenFieldsList.includes('travelExpenses')}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please choose travel expenses method',
                                },
                              ]}
                            >
                              <Select disabled={disabledFieldsList.includes('travelExpenses')}>
                                <Option value='automatic'>Per month (regular work)</Option>
                                <Option value='manual'>Per month (all days)</Option>
                                <Option value='fixed'> Per month (fixed defined value)</Option>
                                <Option value='perDay'>Per day (regular work)</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name='travelExpCurrency'
                              label='Travel expenses currency'
                              labelCol={{ span: 12 }}
                              labelAlign='left'
                              hidden={hiddenFieldsList.includes('travelExpCurrency')}
                            >
                              <Select disabled={disabledFieldsList.includes('travelExpCurrency')}>
                                <Option value='rsd'>RSD</Option>
                                <Option value='eur'>EUR</Option>
                                <Option value='usd'>USD</Option>
                                <Option value='gbp'>GBP</Option>
                                <Option value='rub'>RUB</Option>
                              </Select>
                            </Form.Item>
                            {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}

                            <Form.Item
                              name='salaryCalculationType'
                              label='Fixed vs. Average calculation:'
                              labelCol={{ span: 7 }}
                              labelAlign='left'
                              hidden={hiddenFieldsList.includes('salaryCalculationType')}
                            >
                              <Radio.Group
                                onChange={(event) => setSalaryCalculationType(event.target.value)}
                                defaultValue={salaryCalculationType}
                                disabled={disabledFieldsList.includes('salaryCalculationType')}
                              >
                                <Radio value='average'>Average</Radio>
                                <Radio value='fixed'>Fixed</Radio>
                              </Radio.Group>
                            </Form.Item>
                            {salaryCalculationType === 'fixed' && (
                              <div style={{ backgroundColor: '#f0f5f1' }}>
                                <Form.Item
                                  name='holidayLeaveConfirmation'
                                  label='Holiday leave (fix or average):'
                                  labelCol={{ span: 12 }}
                                  labelAlign='left'
                                  style={{ marginLeft: '10px', paddingTop: '10px' }}
                                  hidden={hiddenFieldsList.includes('holidayLeaveConfirmation')}
                                >
                                  <Radio.Group
                                    defaultValue='average'
                                    disabled={disabledFieldsList.includes('holidayLeaveConfirmation')}
                                  >
                                    <Radio value='average'>Average</Radio>
                                    <Radio value='fixed'>Fixed</Radio>
                                  </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                  name='sickLeaveUpTo30Days65Confirmation'
                                  hidden={hiddenFieldsList.includes('sickLeaveUpTo30Days65Confirmation')}
                                  label='Sick leave up to 30 days 65% (fix or average):'
                                  labelCol={{ span: 12 }}
                                  labelAlign='left'
                                  style={{ marginLeft: '10px' }}
                                >
                                  <Radio.Group
                                    defaultValue='average'
                                    disabled={disabledFieldsList.includes('sickLeaveUpTo30Days65Confirmation')}
                                  >
                                    <Radio value='average'>Average</Radio>
                                    <Radio value='fixed'>Fixed</Radio>
                                  </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                  name='sickLeaveUpTo30Days100Confirmation'
                                  hidden={hiddenFieldsList.includes('sickLeaveUpTo30Days100Confirmation')}
                                  label='Sick leave up to 30 days 100% (fix or average):'
                                  labelCol={{ span: 12 }}
                                  labelAlign='left'
                                  style={{ marginLeft: '10px' }}
                                >
                                  <Radio.Group
                                    defaultValue='average'
                                    disabled={disabledFieldsList.includes('sickLeaveUpTo30Days100Confirmation')}
                                  >
                                    <Radio value='average'>Average</Radio>
                                    <Radio value='fixed'>Fixed</Radio>
                                  </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                  name='paidLeaveConfirmation'
                                  label='Paid leave (fix or average):'
                                  labelCol={{ span: 12 }}
                                  labelAlign='left'
                                  style={{ marginLeft: '10px' }}
                                  hidden={hiddenFieldsList.includes('paidLeaveConfirmation')}
                                >
                                  <Radio.Group
                                    defaultValue='average'
                                    disabled={disabledFieldsList.includes('paidLeaveConfirmation')}
                                  >
                                    <Radio value='average'>Average</Radio>
                                    <Radio value='fixed'>Fixed</Radio>
                                  </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                  name='publicHolidayConfirmation'
                                  hidden={hiddenFieldsList.includes('publicHolidayConfirmation')}
                                  label='Public holiday (fix or average):'
                                  labelCol={{ span: 12 }}
                                  labelAlign='left'
                                  style={{ marginLeft: '10px', paddingBottom: '10px' }}
                                >
                                  <Radio.Group
                                    defaultValue='average'
                                    disabled={disabledFieldsList.includes('publicHolidayConfirmation')}
                                  >
                                    <Radio value='average'>Average</Radio>
                                    <Radio value='fixed'>Fixed</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            )}

                            <div
                              ref={typeOfWorkRef}
                              style={{ position: 'relative', height: '5px', width: '0', opacity: '0' }}
                            ></div>
                            <Form.Item
                              className='typesField'
                              labelAlign='left'
                              labelCol={{ span: 12 }}
                              label='Types of work'
                              name='workTypes'
                              hidden={hiddenFieldsList.includes('workTypes')}
                            >
                              <Select
                                mode='multiple'
                                placement='topLeft'
                                className='type-of-work-select__wrapper'
                                defaultValue={defaultTypeOfWorkValue}
                                tagRender={({ label, value, closable, onClose }) => {
                                  // Prevent removing preselected values
                                  if (defaultTypeOfWorkValue.includes(value)) {
                                    return <Tag style={{ margin: '2px' }}>{label}</Tag>;
                                  }

                                  return (
                                    <Tag closable={closable} onClose={onClose} style={{ margin: '2px' }}>
                                      {label}
                                    </Tag>
                                  );
                                }}
                                disabled={disabledFieldsList.includes('workTypes')}
                                onFocus={() => handleFocusTypeOfWork()}
                              >
                                <Option value='Sick leave up to 30 days 100%'>Sick leave up to 30 days (100%)</Option>
                                <Option value='Sick leave more than 30 days 100%'>
                                  Sick leave more than 30 days (100%)
                                </Option>
                                <Option value='Non-paid leave'>Non-paid leave</Option>
                                <Option value='Pregnancy leave'>Pregnancy leave</Option>
                                <Option value='Weekend work'>Weekend work</Option>
                                <Option value='Night work'>Night work</Option>
                                <Option value='Night work as regular work'>Night work as regular work</Option>
                                <Option value='Night work on public holiday'>Night work on public holiday</Option>
                                <Option value='Overtime work on public holiday'>Overtime work on public holiday</Option>
                              </Select>
                            </Form.Item>

                            {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}

                            <div className='company-checkbox-options'>
                              <Form.Item
                                name='overTime2'
                                hidden={hiddenFieldsList.includes('overTime2')}
                                valuePropName='checked'
                                style={{ textAlign: 'start' }}
                              >
                                <Checkbox disabled={disabledFieldsList.includes('overTime2')}>Overtime 2</Checkbox>
                              </Form.Item>

                              {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                              <Form.Item
                                name='bonus'
                                hidden={hiddenFieldsList.includes('bonus')}
                                valuePropName='checked'
                                style={{ textAlign: 'start' }}
                              >
                                <Checkbox disabled={disabledFieldsList.includes('bonus')} onChange={bonusHandler}>
                                  Bonus
                                </Checkbox>
                              </Form.Item>
                              {isBonusChecked && (
                                <>
                                  <Form.Item
                                    hidden={hiddenFieldsList.includes('isGrossBonus')}
                                    style={{ textAlign: 'start' }}
                                  >
                                    <Checkbox
                                      disabled={disabledFieldsList.includes('isGrossBonus')}
                                      onChange={grossBonusHandler}
                                      checked={isGrossBonusChecked}
                                    >
                                      Is gross bonus?
                                    </Checkbox>
                                    <Tooltip
                                      title={
                                        <span style={{ color: '#333' }}>
                                          Selecting this field will indicate in the Bonus decision that the specified
                                          amount represents the gross value to be paid. If the field is not selected,
                                          the document will reflect the net value.
                                        </span>
                                      }
                                      color='#fff'
                                      placement='top'
                                      autoAdjustOverflow={true}
                                    >
                                      <QuestionCircleOutlined
                                        style={{
                                          marginTop: '2px',
                                          color: '#b1b1b1',
                                          cursor: 'pointer',
                                        }}
                                      />
                                    </Tooltip>
                                  </Form.Item>

                                  <Form.Item
                                    name='bonusCurrency'
                                    hidden={hiddenFieldsList.includes('bonusCurrency')}
                                    label='Bonus currency'
                                    labelCol={{ span: 12 }}
                                    labelAlign='left'
                                  >
                                    <Select disabled={disabledFieldsList.includes('bonusCurrency')}>
                                      <Option value='rsd'>RSD</Option>
                                      <Option value='eur'>EUR</Option>
                                      <Option value='usd'>USD</Option>
                                      <Option value='gbp'>GBP</Option>
                                      <Option value='rub'>RUB</Option>
                                    </Select>
                                  </Form.Item>
                                </>
                              )}
                              {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}

                              <Form.Item
                                name='otherIncome'
                                hidden={hiddenFieldsList.includes('otherIncome')}
                                valuePropName='checked'
                                style={{ textAlign: 'start' }}
                              >
                                <Checkbox
                                  disabled={disabledFieldsList.includes('otherIncome')}
                                  onChange={otherIncomeHandler}
                                >
                                  Other income on salary
                                </Checkbox>
                              </Form.Item>
                              {isOtherIncomeChecked && (
                                <div>
                                  <Form.Item
                                    name='otherIncomeCurrency'
                                    hidden={hiddenFieldsList.includes('otherIncomeCurrency')}
                                    label='Other income currency'
                                    labelAlign='left'
                                    labelCol={{ span: 12 }}
                                  >
                                    <Select disabled={disabledFieldsList.includes('otherIncomeCurrency')}>
                                      <Option value='rsd'>RSD</Option>
                                      <Option value='eur'>EUR</Option>
                                      <Option value='usd'>USD</Option>
                                      <Option value='gbp'>GBP</Option>
                                      <Option value='rub'>RUB</Option>
                                    </Select>
                                  </Form.Item>
                                  {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                                </div>
                              )}

                              <Form.Item
                                name='suspension'
                                hidden={hiddenFieldsList.includes('suspension')}
                                valuePropName='checked'
                                style={{ textAlign: 'start' }}
                              >
                                <Checkbox
                                  disabled={disabledFieldsList.includes('suspension')}
                                  onChange={suspensionHandler}
                                >
                                  Suspension of earnings
                                </Checkbox>
                              </Form.Item>

                              {isSuspensionChecked && (
                                <div>
                                  <Form.Item
                                    name='suspensionCurrency'
                                    hidden={hiddenFieldsList.includes('suspensionCurrency')}
                                    label='Suspension currency'
                                    labelAlign='left'
                                    labelCol={{ span: 12 }}
                                  >
                                    <Select disabled={disabledFieldsList.includes('suspensionCurrency')}>
                                      <Option value='rsd'>RSD</Option>
                                      <Option value='eur'>EUR</Option>
                                      <Option value='usd'>USD</Option>
                                      <Option value='gbp'>GBP</Option>
                                      <Option value='rub'>RUB</Option>
                                    </Select>
                                  </Form.Item>
                                  {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                                </div>
                              )}

                              <Form.Item
                                name='otherExpenses'
                                hidden={hiddenFieldsList.includes('otherExpenses')}
                                valuePropName='checked'
                                style={{ textAlign: 'start' }}
                              >
                                <Checkbox
                                  disabled={disabledFieldsList.includes('otherExpenses')}
                                  onChange={otherExpensesHandler}
                                >
                                  Other paid expenses
                                </Checkbox>
                              </Form.Item>

                              {isOtherExpensesChecked && (
                                <div>
                                  <Form.Item
                                    name='otherExpensesCurrency'
                                    hidden={hiddenFieldsList.includes('otherExpensesCurrency')}
                                    label='Other expenses currency'
                                    labelAlign='left'
                                    labelCol={{ span: 12 }}
                                  >
                                    <Select disabled={disabledFieldsList.includes('otherExpensesCurrency')}>
                                      <Option value='rsd'>RSD</Option>
                                      <Option value='eur'>EUR</Option>
                                      <Option value='usd'>USD</Option>
                                      <Option value='gbp'>GBP</Option>
                                      <Option value='rub'>RUB</Option>
                                    </Select>
                                  </Form.Item>
                                  {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {isHrChecked && (
                          <div>
                            <Form.Item
                              valuePropName='checked'
                              name='isSector'
                              hidden={hiddenFieldsList.includes('isSector')}
                              style={{ textAlign: 'start' }}
                            >
                              <Checkbox disabled={disabledFieldsList.includes('isSector')} onChange={sectorsHandler}>
                                Sectors
                              </Checkbox>
                            </Form.Item>
                            {isSectorsChecked && (
                              <Form.Item
                                valuePropName='checked'
                                name='isHrOfficerSelfApprove'
                                hidden={hiddenFieldsList.includes('isSector')}
                                style={{ textAlign: 'start' }}
                              >
                                <Checkbox
                                  checked={isHrOfficerSelfApprove}
                                  disabled={disabledFieldsList.includes('isSector')}
                                  onChange={sectorsSelfApproveHandler}
                                >
                                  Is HR officer with self approve
                                </Checkbox>
                                <Tooltip
                                  title='HR officer can create and approve requests for self.'
                                  color='#b1b1b1'
                                  placement='top'
                                  autoAdjustOverflow={true}
                                >
                                  <QuestionCircleOutlined
                                    style={{
                                      marginTop: '1px',
                                      color: '#b1b1b1',
                                      cursor: 'pointer',
                                      marginBottom: '24px',
                                    }}
                                  />
                                </Tooltip>
                              </Form.Item>
                            )}
                            {canUserUpdate ? <Divider style={{ backgroundColor: '#2db1ab50' }} /> : null}
                          </div>
                        )}
                        <div style={{ margin: 0, display: 'flex' }}>
                          <Form.Item
                            valuePropName='checked'
                            style={{ textAlign: 'start' }}
                            hidden={hiddenFieldsList.includes('foreignWorkPermitTracker')}
                          >
                            <Checkbox
                              onChange={(e) => foreignWorkPermitTracker(e)}
                              checked={isForeignWorkPermitTracker}
                            >
                              Work permit expiry date tracker
                            </Checkbox>

                            <Tooltip
                              title='This functionality is intended only for foreign employees.'
                              color='#b1b1b1'
                              placement='top'
                              autoAdjustOverflow={true}
                            >
                              <QuestionCircleOutlined
                                style={{
                                  marginTop: '1px',
                                  color: '#b1b1b1',
                                  cursor: 'pointer',
                                  marginBottom: '24px',
                                }}
                              />
                            </Tooltip>
                          </Form.Item>
                        </div>

                        <Form.Item
                          valuePropName='checked'
                          name='holidayDaysTracker'
                          hidden={hiddenFieldsList.includes('holidayDaysTracker')}
                          style={{ textAlign: 'start' }}
                        >
                          <Checkbox
                            disabled={disabledFieldsList.includes('holidayDaysTracker')}
                            onChange={holidayDaysTracker}
                          >
                            Holiday days tracker
                          </Checkbox>
                        </Form.Item>
                        {isHolidayDaysTracker && (
                          <>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              {!hiddenFieldsList.includes('holidayDaysTracker') ? (
                                <>
                                  <DatePicker
                                    format={'DD-MM-YYYY'}
                                    onChange={(val) => setTrackerStartDate(val)}
                                    value={trackerStartDate}
                                    style={{ display: 'flex', alignItems: 'flex-start', width: '25%' }}
                                  />
                                  <Tooltip
                                    title='Pick the date here in order to set the starting date of holiday tracker usage.'
                                    color='#b1b1b1'
                                    placement='top'
                                    autoAdjustOverflow={true}
                                  >
                                    <QuestionCircleOutlined
                                      style={{
                                        marginLeft: '7px',
                                        marginTop: '1px',
                                        color: '#b1b1b1',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              ) : null}
                            </div>
                            <Divider style={{ backgroundColor: '#2db1ab50' }} />
                          </>
                        )}
                        {canUserUpdate ? (
                          <Row>
                            <Col md={18}>
                              <Form.Item label='Recipient email' name='recipient' style={{ marginTop: '20px' }}>
                                <Input type='email' />
                              </Form.Item>
                            </Col>
                            <Col md={6} style={{ marginTop: '20px' }}>
                              {/* TODO - Slokky check this out */}
                              <Button onClick={addEmail}>Add email</Button>
                            </Col>
                          </Row>
                        ) : null}
                        {canUserUpdate &&
                          emails &&
                          emails.length !== 0 &&
                          emails.map((email, index) => {
                            return (
                              <Row key={index}>
                                <Col md={18}>
                                  <Form.Item name={['emailList', index]}>
                                    <Input type='email' defaultValue={email} />
                                  </Form.Item>
                                </Col>
                                <Col md={6}>
                                  <Button
                                    onClick={() => changeEmail(index, form.getFieldValue('emailList'))}
                                    style={{ marginRight: '10px', marginLeft: '-20px' }}
                                  >
                                    Edit
                                  </Button>
                                  <DeleteOutlined onClick={() => removeEmail(index)} />
                                </Col>
                              </Row>
                            );
                          })}
                        {canUserUpdate ? (
                          <Button onClick={createAccounts} style={{ float: 'left', marginTop: '10px' }}>
                            Create accounts for HR
                          </Button>
                        ) : null}
                        <Form.Item {...tailLayout}>
                          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }}>
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                      <CompanyOwnershipDrawer
                        data={data?.ownershipStructure}
                        open={companyOwnershipDrawer}
                        onClose={onClose}
                        onSubmit={handleDrawerSubmit}
                      />
                    </div>
                  )}
                </TabPane>
                <TabPane tab='Questionnaire' key='2'>
                  <QuestionnaireForm data={data} companyId={companyId} user={currentuser} SERVER_URL={SERVER_URL} />
                </TabPane>
                {canUserUpdate && company.type === 'main' && (
                  <TabPane tab='Payment instructions' key='3'>
                    <PaymentSlipsForm
                      createHandler={createPaymentSlip}
                      companyIsNew={isNew}
                      createdPaymentSlips={createdPaymentSlips}
                      paymentSlipsEmailReceivers={paymentSlipsEmailReceivers}
                      setPaymentSlipsEmailReceivers={setPaymentSlipsEmailReceivers}
                      paymentVisible={true}
                    />
                    <Button
                      type='primary'
                      onClick={() => handleFormSubmission(formRef.current.getFieldsValue(), 'payment')}
                      style={{ float: 'center', marginTop: '10px' }}
                    >
                      Submit
                    </Button>
                  </TabPane>
                )}
                {user.role.includes('admin') || user.role.includes('SuperAdmin') ? (
                  <TabPane tab='Create documents for company' key='4'>
                    <StatuaryDocsForm data={data} user={user} SERVER_URL={SERVER_URL} />
                    <FunctionaryAndOwnershipForm data={data} user={user} SERVER_URL={SERVER_URL} />
                  </TabPane>
                ) : null}
                {user.role.includes('admin') ||
                user.role.includes('SuperAdmin') ||
                user.role.includes('user') ||
                user.role.includes('userOfficer') ? (
                  <TabPane tab='Upload and generate documents' key='5'>
                    <UploadCompanyFilesForm user={user} SERVER_URL={SERVER_URL} />
                  </TabPane>
                ) : null}
              </Tabs>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;
