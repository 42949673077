import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import { formatDateForTables } from '../../helpers/dates';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CompanyTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'createdBy',
  'authorizedPerson',
  'documentReturnType',
  'documentSeparateRequestResponse',
  'typeOfHr',
  'modifiedBy',
  'zip',
  'address',
  'code',
  'calculationMethod',
  'calculationMethodMeal',
  'calculationMethodHoliday',
  'workTypes',
  'travelExpenses',
  'overTime2',
  'otherIncome',
  'suspension',
  'otherExpenses',
  'mealCurrency',
  'holidayCurrency',
  'travelExpCurrency',
  'type',
  'mainCompany',
  'emailReceiver',
  'isHr',
  'workFromHomeType',
  'workFromHomeCurrency',
  'calculationMethodWorkFromHome',
  'bonusCurrency',
  'bonus',
  'suspension',
  'workFromHome',
  'bankAccount',
  'phoneNumber',
  'apiKey',
  'eInvoice',
  'otherExpensesCurrency',
  'otherExpensesValue',
  'otherIncomeCurrency',
  'otherIncomeValue',
  'suspensionCurrency',
  'suspensionValue',
  'sectors',
  'isSector',
  'eInvoiceOfficer',
  'trackerStartDate',
  'holidayDaysTracker',
  'userOfficer',
  'salaryCalculationType',
  'companyAdmin', // this column is added additionally in the table
  'holidayLeaveConfirmation',
  'sickLeaveUpTo30Days65Confirmation',
  'sickLeaveUpTo30Days100Confirmation',
  'paidLeaveConfirmation',
  'publicHolidayConfirmation',
  'paymentSlips',
  'paymentSlipsEmailReceivers',
  'selectedSortingType',
  'companyAdminReceiveHREmails',
  'companyDocsSettings',
  'foreignWorkPermitTracker',
  'weekendLeaves',
  'email',
  'authorizedPersonJmbg',
  'authorizedPersonPassportNumber',
  'qrInEInvoices',
  'companyLogo',
  'showCompanyLogoInDocs',
  'ownershipStructure',
  'isForEmployees',
  'bankAccountsArray',
  'showCompanyLogoInDocs',
  'questionnarie',
  'questionnarieDocs',
  'isHrOfficerSelfApprove',
  'isSpecialHolidayAllowanceCalculation',
  'isGrossBonus',
];

const Company = () => {
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
      fetchCompanies(`${SERVER_URL}/companies`, []);
    } else if (
      currentuser.data.role.includes('hrOfficer') &&
      currentuser.data.sectorId &&
      !currentuser.data.role.includes('user')
    ) {
      // fetch data/employees to get company id from employee from sector.
      // this is when logged user is with hrOfficer role without client/user role
      const filter = { employeeSector: currentuser.data.sectorId };
      fetchData(`${SERVER_URL}/data?filter=${JSON.stringify(filter)}&user=${currentuser.data.id}`, []);
    } else if (currentuser.data.role.includes('eInvoiceOfficer') && !currentuser.data.role.includes('user')) {
      const filter = { eInvoiceOfficer: currentuser.data.id };
      fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
    } else if (currentuser.data.role.includes('userOfficer') && !currentuser.data.role.includes('user')) {
      const filter = { userOfficer: currentuser.data.id };
      fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
    } else {
      let filter = { client: currentuser.data.id };
      if (companies.data.length === 0) {
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
      if (companies && companies.data && companies.data.items && companies.data.items.length === 1) {
        filter = { $or: [{ _id: companies.data.items[0]._id }, { mainCompany: companies.data.items[0]._id }] };
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
    }
  }, [fetchCompanies, currentuser.data.id, currentuser.data.role]);

  // use company id from employee from specific sector. To get company for logged hrOfficer
  // this is when logged user is with hrOfficer role without client/user role
  useEffect(() => {
    if (data && data.data && data.data.items) {
      let companyId;
      data.data.items.map((item) => {
        companyId = item?.company?._id;
      });

      if (companyId) {
        const filter = { $or: [{ _id: companyId }, { mainCompany: companyId }] };
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
    }
  }, [data]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/companies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Company is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (companies.data.items && companies.data.items.length > 0) {
    // helper to get array all keys
    const everyKey = companies.data.items.map((item) => Object.keys(item));
    const uniqueStringsMap = new Map();
    for (const nestedList of everyKey) {
      for (const string of nestedList) {
        uniqueStringsMap.set(string, true);
      }
    }

    const uniqueStringsArray = Array.from(uniqueStringsMap.keys());
    const keys = [...new Set(uniqueStringsArray)];
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));

    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'name') {
        alternativeNames.push('Name of company');
      } else if (columnKey === 'code') {
        alternativeNames.push('Registration number');
      } else if (columnKey === 'pib') {
        alternativeNames.push('TIN');
      } else if (columnKey === 'city') {
        alternativeNames.push('Address');
      } else if (columnKey === 'client') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('Created on');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('Updated on');
      } else if (columnKey === 'eInvoiceOfficer') {
        alternativeNames.push('E-invoice officer');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }

  let tableData = [];
  if (companies.data.items && companies.data.items.length > 0) {
    tableData = companies.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      const formattedDate = formatDateForTables(item.createdAt);
      const formattedDate2 = formatDateForTables(item.updatedAt);

      if (item.client && item.client.firstName && item.client.lastName) {
        item.client = item.client.firstName + ' ' + item.client.lastName;
      }

      if (Array.isArray(item.companyAdmin)) {
        const formattedCompanyAdmin = item.companyAdmin
          .filter((i) => i && i.firstName && i.lastName)
          .map((i) => `${i.firstName} ${i.lastName}`)
          .join(', ');

        item.companyAdmin = formattedCompanyAdmin;
      }
      return { ...item, createdAt: formattedDate, updatedAt: formattedDate2 };
    });
  }

  const updateSize = () => {
    setWindowWidth(window.innerWidth);
  };

  // triggers upon change of screen size
  window.addEventListener('resize', updateSize);

  return (
    <div style={{ padding: '8px' }}>
      {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) && (
        <div className='actions-block' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Link to='/admin/new-company'>
            <Button type='primary'>New company</Button>
          </Link>
          {windowWidth > 750 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'fixed',
                right: '0',
                paddingRight: '30px',
                fontSize: '0.9rem',
              }}
            >
              <div style={{ marginRight: '10px' }}>Legend:</div>
              <div style={{ marginRight: '10px', color: 'red' }}>companies without Client & EInvoice Officer,</div>
              <div style={{ color: 'blue' }}>eInvoice companies</div>
            </div>
          )}
        </div>
      )}

      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {companies.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!companies.isLoading && companies.data.items && companies.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={newColumnKeys}
            editPath='/admin/edit-company/'
            viewPath='/admin/view-company/'
          />
        )}
        {!companies.isLoading && companies.data.items && companies.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Company;
